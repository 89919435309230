import { render, staticRenderFns } from "./VerifyFalse.vue?vue&type=template&id=47907e49&scoped=true"
import script from "./VerifyFalse.vue?vue&type=script&lang=js"
export * from "./VerifyFalse.vue?vue&type=script&lang=js"
import style0 from "./VerifyFalse.vue?vue&type=style&index=0&id=47907e49&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47907e49",
  null
  
)

export default component.exports